import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import About from './pages/about/About';
import Generator from './pages/generator/Generator';
import Blog from './pages/blog/Blog';
import Apps from './pages/apps/Apps';
import Contact from './pages/contact/Contact';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="about" element={<About />} />
      <Route path="qr-code-generator-online" element={<Generator />} />
      <Route path="blog" element={<Blog />} />
      <Route path="apps" element={<Apps />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
