import React from 'react';
import Question from '../question/Question';
import './faq.css';

const FAQ = () => {

    return (
        <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
            <div className="gpt3__whatgpt3-feature">
            <Question title="QR code scanner online" 
                text="QrCodeScan.Online is the fastest tool to use for scanning all QR code types easily and without installing an app." />
            </div>
            <div className="gpt3__whatgpt3-heading">
            <h1 id="faq" className="gradient__text">FAQ</h1>
            </div>
            <div className="gpt3__whatgpt3-container">
            <Question title="How do I scan QR codes online?" 
                text="It’s easy, you just give this website the permission to use your camera, then you have to point the camera towards the QR code.<br/>
                The result will be shown bellow the camera frame. And depending on the type of the QR code scanned, you will be provided with the appropriate shortcuts." />
            <Question title="How do I scan QR code online from image?" 
                text="If you have a QR code saved in your device or gallery as an image, and you want to scan this QR code online from image; you have to click on the gallery icon on the bottom right of the camera frame. Then a picker will be shown to choose you image.<br/>
                As by using camera, the result will be shown bellow the camera frame, and then you can see the result and use all available shortcuts." />
            <Question title="Is it safe to give this website the camera permission?" 
                text="Sure, we use camera only to scan QR Codes. So, it’s safe." />
            <Question title="Can I use this website for scanning QR Codes from images only?" 
                text="Yes. You can deny camera permission and continue using this tool for scanning QR code from image." />
            <Question title="Have you an app that can I install in my device?" 
                text="Yes, we have an app that works in android devices, you can install it from Google Play Store: <a href='https://play.google.com/store/apps/details?id=com.lookandfeel.qrcodescanner' title='QR Code Scanner' rel='noreferrer' target='_blank'>QR Code Scanner Android App</a>" />
            </div>
        </div>
    );
};

export default FAQ;