import React from 'react';
import { Navbar, Footer } from '../../components';
import './about.css';

const About = () => (
    <div className="App">
    <div className="gradient__bg">
      <Navbar />
    </div>
    <div className="gpt3__about section__margin">
      <div className="gpt3__about-heading">
        <h1 className="gradient__text">About us</h1>
      </div>
      <div className="gpt3__about-text">
        <p>Welcome to LookAndFeel Lab, your number one source for all Web and Mobile apps. We're dedicated to giving you the very best of Web and Moblie apps, with a focus on Quality, Privacy, and Time.</p>
        <p>Founded in 2016 by H.Sabah, LookAndFeel Lab has come a long way from its beginnings in Morocco. When Sabah first started out, her passion for Web drove them to quit day job so that LookAndFeel Lab can offer you the world's most advanced Web and Mobile apps. We now serve customers all over the World, and are thrilled that we're able to turn our passion into my own website.</p>
        <p>I hope you enjoy my products as much as I enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact me.</p>
        Sincerely,<br />
        H.Sabah
    </div>
    </div>
    <Footer />
  </div>
);

export default About;