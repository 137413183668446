import React from 'react';
import { Navbar, Footer } from '../../components';

const Generator = () => (
    <div className="App">
    <div className="gradient__bg">
      <Navbar />
    </div>
    <div>
        <h1>QR Code Generator</h1>
    </div>
    <Footer />
  </div>
);

export default Generator;