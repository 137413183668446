import './App.css';
import { Navbar, Scanner, FAQ, Footer } from './components';

function App() {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Scanner />
      </div>
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
