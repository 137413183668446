import React from 'react';
import './scanner.css';

const Scanner = () => {

    return (
        <div className="gpt3__header section__padding" id="home">
            QR Code Scanner
        </div>
    );
};

export default Scanner;