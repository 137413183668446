import React from 'react';
import { Navbar, Footer } from '../../components';

const Apps = () => (
    <div className="App">
    <div className="gradient__bg">
      <Navbar />
    </div>
    <div>
        <h1>Apps</h1>
    </div>
    <Footer />
  </div>
);

export default Apps;